import React from 'react';
import { Remarkable } from 'remarkable';
import './organization-doc.css'

const mark = new Remarkable();

const input = `
  # Organization Documentation
  
  Interested in using the Awair Developer APIs to access and manage your Organization’s air data?\n
  This section will walk you through the structure of an Organization, Access Management, and how to access specific a device’s air data.

  ## Creating an Organization account
  Learn more about Awair’s Enterprise offerings here: <http://awairbusiness.com>

  ## Register for an access to Dashboard

  Log in or sign up for Dashboard here: <https://dashboard.getawair.com>

  ## Using the Organization API

  View the Organization documentation here: <https://docs.developer.getawair.com/#tag/Organization>

  ## Organization Structure
    
  The **Owner** can create and manage multiple **Organizations**. Each **Organization** can contain multiple **Groups** to organize devices.**Organizations** can also contain multiple **Floorplans** to organize and visualize device data.\n
  The **Owner** can invite **Admin(s)** to each **Organization**, who have access to all Dashboard features, devices, and **Groups**.The **Owner** can also invite **Member(s)** to specific **Group(s)** as view-only users.\n
  **Members** can only see their own devices that they bring into the **Organization** from setting the devices up with their Awair accounts as well as the devices assigned to their **Group(s)**. 
  **Members** only have access to a limited number of features within the Dashboard.\n
  **Admins** can invite **Members** to **Groups**, but they cannot invite other **Admins**.

  ## Accessing Device Data

  1. Retrieve a list of **Organizations** your account belongs to:

            curl -X GET http://developer-apis.awair.is/v1/org-users/self/orgs -H 'authorization: Bearer {{YourTokenHere}}'

  2. Use the **org_id** from the previous step to retrieve a list of users in the **Organization**:

            curl -X GET http://developer-apis.awair.is/v1/orgs/:org_id/org-users -H 'authorization: Bearer {{YourTokenHere}}'

  3. Use the **org_id** from the first step and the list of **user_id(s)** from the second step (as query parameters) to retrieve a list of **devices**:

            curl -X GET http://developer-apis.awair.is/v1/orgs/:org_id/org-users/devices?user_id=:user_id&user_id=:user_id&... -H 'authorization: Bearer {{YourTokenHere}}'

  4. Use the **org_id** and each **device_type** and **device_id** pair to retrieve air data (e.g. /latest):
  
            curl -X GET http://developer-apis.awair.is/v1/orgs/:org_id/devices/:device_type/:device_type/air-data/latest -H 'authorization: Bearer {{YourTokenHere}}'
      
`

const OrgDoc = () => {
  return (
    <div className="OrgDocPane">
      <div className="DocPane">
        <div className="markdown-body" dangerouslySetInnerHTML={{__html:mark.render(input)}}></div>
      </div>
    </div>
  );
}

export default OrgDoc;

