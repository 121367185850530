import React from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './login/Login';
import Users from './users/Users';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloProvider,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
} from '@apollo/client';

const { Content, Header } = Layout;

const httpLink = createHttpLink({
  uri: apiHostName(),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('adminToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function apiHostName() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-console-apis.awair.is/admin-graphql';
  } else if (env === 'stg') {
    return 'https://stg-developer-console-apis.awair.is/admin-graphql';
  } else {
    return 'https://developer-console-apis.awair.is/admin-graphql';
  }
}

class Admin extends React.Component {
  state = {
    refreshed: false,
  };

  render() {
    if (this.state.refreshed) {
      this.setState({ refreshed: false });
    }
    return (
      <div className="Layout">
        <Header className="App-header">
          <a href="/">
            <img
              className="Logo sharpen-img"
              alt="Awair Logo"
              src="https://s3-us-west-1.amazonaws.com/bitfinder/img/AWAIR+Developer+logo.png"
              height="30"
            />
          </a>
        </Header>
        <Layout className="MainLayout">
          <Content className="MainContent">
            <Switch>
              <Route path="/admin/login" render={() => <Login />} />
              <Route
                path="/admin/users"
                render={() => (
                  <ApolloProvider client={client}>
                    <Users />
                  </ApolloProvider>
                )}
              />
              <Redirect from="/admin" exact to="/admin/login" />
            </Switch>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default Admin;
