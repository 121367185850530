import React from 'react';
import '../onboard.css';
import './Welcome.css';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import { Redirect, BrowserRouter as Router } from 'react-router-dom';

const Welcome = () => {
  if (JSON.parse(localStorage.getItem('consoleAccessToken')) && JSON.parse(localStorage.getItem('isApproved'))) {
    return (
      <Router>
        <Redirect to="/console/access-token" />
      </Router>
    );
  } else {
    return (
      <div className="OnboardMainPane">
        <h1 className="WelcomeTitle">Build apps for IAQ</h1>
        <div className="WelcomeDescription">
          <p>Take control of your indoor air quality by integrating with Awair.</p>
          <p>
            From automating your own smart home setup to managing a large commercial building, Awair has got your back.
          </p>
        </div>
        <Button type="primary" className="StartBuildingButton">
          <a href="/onboard/login">Start Building</a>
        </Button>
        <div className="DocumentLink">
          <p>
            Explore the <a href="https://docs.developer.getawair.com/">Documentation</a>
          </p>
        </div>
      </div>
    );
  }
};

export default Welcome;
