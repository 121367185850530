import React from 'react';
import { Card, Icon } from 'antd';
import './RequestAccessPending.css';

const RequestAccessPending = () => {
  return (
    <div className="OnboardMainPane">
      <Card className="RequestAccessCard">
        <div><h3>Account Request Submitted and Pending</h3></div>
        <p>Your account is pending. We will email you when your account request has been reviewed.</p>
        <p>If you have received an email saying that you were approved for Developer Console and seeing this page, go to sign in page, refresh the browser, and start over.</p>
        <div className="RequestCompleteImgContainer"><Icon type="check-circle-o" className="SuccessCheck"/></div>
      </Card>
    </div>
  )
}

export default RequestAccessPending;
