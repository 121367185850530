import React from 'react';
import './Tiers.css';
import { Row, Col, Card, Icon } from 'antd';
import 'antd/dist/antd.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'

const tierName = JSON.parse(localStorage.getItem("tierName"));

const Tiers = () => {
  return (
    <div className="OnboardMainPane">
      <div className="TiersPane">
        <div>
          <h1 className="TiersTitle">Pricing that Works Just For You</h1>
          <div className="TiersDescription">
            <p>Please reach out to <a href="mailto:hello@getawair.com" target="_top">hello@getawair.com</a> to upgrade your tier</p>
          </div>
        </div>
        <Row className="TiersRow" gutter={35}>
          <Col className="gutter-row" span={6}>
            <div className={ tierName === "HOBBYIST" ? "gutter-box-selected" : "gutter-box" }>
              <Card title="Hobbyist" className="TierCard" bordered={false}>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>15 Min Avg Sensor Data</Col>
                  <Col span={4}>100</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>5 Min Avg Sensor Data</Col>
                  <Col span={4}>300</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Raw Sensor Data</Col>
                  <Col span={4}>500</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Latest Sensor Data</Col>
                  <Col span={4}>300</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Read User & Device info</Col>
                  <Col span={4}>10</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>Write User & Device info</Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Actionable Tips</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Webhooks</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>Org Management</Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Floor plan management</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Environment Reports</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Intelligent Analysis</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>OAuth 2.0 Integration</Col>
                  <Col span={4}>-</Col>
                </Row>
                <br/>
                <Row className="FeatureRow">
                  <div className="PricingValue">Free</div>
                </Row>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className={ tierName === "DEVELOPER" ? "gutter-box-selected" : "gutter-box" }>
              <Card title="Developer" className="TierCard" bordered={false}>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>15 Min Avg Sensor Data</Col>
                  <Col span={4}>500</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>5 Min Avg Sensor Data</Col>
                  <Col span={4}>1500</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Raw Sensor Data</Col>
                  <Col span={4}>2500</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Latest Sensor Data</Col>
                  <Col span={4}>3000</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Read User & Device info</Col>
                  <Col span={4}>25</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Write User & Device info</Col>
                  <Col span={4}>25</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Actionable Tips</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Webhooks</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>Org Management</Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Floor plan management</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Environment Reports</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Intelligent Analysis</div></Col>
                  <Col span={4}>-</Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>OAuth 2.0 Integration</Col>
                  <Col span={4}>-</Col>
                </Row>
                <br/>
                <Row className="FeatureRow">
                  <div className="PricingValue"> <a href="mailto:hello@getawair.com">Contact Us</a></div>
                </Row>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className={ tierName === "ENETRPRISE" ? "gutter-box-selected" : "gutter-box" }>
              <Card title="Enterprise" className="TierCard" bordered={false}>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>15 Min Avg Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>5 Min Avg Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Raw Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Latest Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Read User & Device info</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Write User & Device info</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Actionable Tips</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Webhooks</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Org Management</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Floor plan management</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Environment Reports</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Intelligent Analysis</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="close" className="XMark"/></Col>
                  <Col span={18}>OAuth 2.0 Integration</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <br/>
                <Row className="FeatureRow">
                  <div className="PricingValue"> <a href="mailto:hello@getawair.com">Contact Us</a></div>
                </Row>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div className={ tierName === "APP_DEVELOPER" ? "gutter-box-selected" : "gutter-box" }>
              <Card title="App Developer" className="TierCard" bordered={false}>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>15 Min Avg Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>5 Min Avg Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Raw Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Latest Sensor Data</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Read User & Device info</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Write User & Device info</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Actionable Tips</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Webhooks</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>Org Management</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Floor plan management</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Environment Reports</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}><div class="Unimplemented">Intelligent Analysis</div></Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <Row className="FeatureRow">
                  <Col span={2}><Icon type="check" className="CheckMark"/></Col>
                  <Col span={18}>OAuth 2.0 Integration</Col>
                  <Col span={4}><FontAwesomeIcon icon={faInfinity} style={{"color":"grey"}}/></Col>
                </Row>
                <br/>
                <Row className="FeatureRow">
                  <div className="PricingValue"> <a href="mailto:hello@getawair.com">Contact Us</a></div>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Tiers;
