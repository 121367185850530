import { gql } from '@apollo/client';

// Admin
export const LOGIN_ADMIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      consoleAccessToken
    }
  }
`;

// Login
export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      firstName
      lastName
      isApproved
      consoleAccessToken
      apiAccessToken
      hasRequestedAccess
    }
  }
`;

export const REQUEST_ACCESS = gql`
  mutation requestAccess($userId: String!) {
    requestAccess(userId: $userId) {
      consoleAccessToken
      apiAccessToken
    }
  }
`;

// Users
export const USER_FROM_ID = gql`
  query userFromId($userId: String!) {
    userFromId(userId: $userId) {
      id
      email
      firstName
      lastName
      isApproved
      hasRequestedAccess
      developerConsoleAcceptanceCode
      tier
    }
  }
`;

export const USER_FROM_EMAIL = gql`
  query userFromEmail($email: String!) {
    userFromEmail(email: $email) {
      id
      email
      firstName
      lastName
      isApproved
      hasRequestedAccess
      developerConsoleAcceptanceCode
      tier
    }
  }
`;

export const PUT_TIER = gql`
  mutation putTierForUser($userId: String!, $tier: String!) {
    putTierForUser(userId: $userId, tier: $tier)
  }
`;

export const ACCEPT_USER = gql`
  mutation acceptUser($userId: String!, $code: String!) {
    acceptUser(userId: $userId, acceptanceCode: $code)
  }
`;

// OAuth2
export const CLIENTS_QUERY = gql`
  query q {
    clients {
      id
      name
      description
      secret
      redirectUri
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $description: String!, $redirectUri: String!) {
    createClient(name: $name, description: $description, redirectUri: $redirectUri)
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($clientId: String!) {
    deleteClient(clientId: $clientId)
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($clientId: String!, $name: String!, $description: String!, $redirectUri: String!) {
    updateClient(clientId: $clientId, name: $name, description: $description, redirectUri: $redirectUri)
  }
`;
