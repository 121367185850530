import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, Button, notification } from 'antd';
import './AccessToken.css';
import '../Console.css';

const AccessToken = () => {
  const onCopy = () => {
    notification.open({
      message: `Copied to Clipboard`,
      duration: 1,
      placement: 'bottomRight',
    });
  };
  return (
    <div className="MainPane">
      <div className="Header">
        <h1>Access Token</h1>
        <Card className="MainCard" style={{ width: '100%', maxWidth: 600 }}>
          <p>Use the token below to access the Developer API endpoints.</p>
          <div className="Credentials">
            <p className="MiniTitle">ACCESS TOKEN</p>
            <div className="CredentialField">
              {JSON.parse(localStorage.getItem('apiAccessToken'))}
            </div>
          </div>
          <br />
          <p>
            Navigate to the Getting Started page for a quickstart guide or
            follow the link below to the full API documentation.
          </p>
          <Button type="primary" href="http://docs.developer.getawair.com">
            Read API Documentation
          </Button>
          <CopyToClipboard
            onCopy={onCopy}
            text={JSON.parse(localStorage.getItem('apiAccessToken'))}
          >
            <Button className="Buttons" type="primary">
              Copy To Clipboard
            </Button>
          </CopyToClipboard>
        </Card>
      </div>
    </div>
  );
};

export default AccessToken;
