import React from 'react';
import { Button } from 'antd';
import './GettingStartedDoc.css';
import { Remarkable } from 'remarkable';

const md = new Remarkable();

const graphQlHostName = () => {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-apis.awair.is';
  } else if (env === 'stg') {
    return 'https://stg-developer-apis.awair.is';
  } else {
    return 'https://developer-apis.awair.is';
  }
};

const firstHalf = `# Getting Started
  Welcome to Awair Developer APIs! This page aims to get you started quickly and explain some basic concepts.

  ## The Developer Console

  The Developer Console is your gateway to the Developer APIs. From here you can manage your account, get your account API token and register OAuth 2.0 clients.

  #### Account Token

  Your _account token_ is an API token that is automatically generated for your account.
  This token is all you need to start using our APIs, but note that which endpoints you are authorised to use depends on the permissions you have activated for your account or clients.

  #### OAuth 2.0 Clients

  OAuth 2.0 clients provide you with a way to create your own apps which can request access to your users' Awair data.
  These clients must be registered in the Developer Console first.
  After this step, your clients will be displayed in the [OAuth 2.0](/console/oauth2) section of the developer console and you can use the information displayed there to implement the OAuth 2.0 token exchange flow.
  More information on this can be found in the OAuth 2.0 [documentation](/console/oauth2-doc) section.

  ## Permissions

  Each endpoint requires a specific permission. These will be selected and activated through the Developer Console - but for now you should contact us. 
  
  Default permissions are activated free of charge when you first register to the Developer Console or create a Client, but the number of requests per month and range of available endpoints will be limited.
  
  You can modify your permissions by contacting _developer@getawair.com_.

  #### Account Tiers

  Your account has a _Tier_, which determines which endpoints you have access to, as well as how many requests you can make each day.

  Here is the list of Tiers, from least to most access:

   * Hobbyist
   * Small Developer
   * Large Developer
   * Enterprise

  ## First Steps

  All our endpoints use an access token for authorization. It should be include in the \`Authorization\` header of any requests

      curl -X GET https://developer-apis.awair.is/ -H 'authorization: Bearer {{yourAccessToken}}'
  
  The access token can be copied straight from the [Access Token](/console/access-token) section if you want use your own token, or generated through the OAuth 2.0 flow.

  All the endpoints are documented in our [API documentation](http://docs.developer.getawair.com), which we are always improving.

  ## Data Units

  A full guide to the units returned through our Developer API and an explanation of the Awair scoring system can be found in the [data documentation](/console/data-docs) section.

  ## Exploring with GraphQL

  Most of our endpoints are exposed through GraphQL. The GraphiQL tool is a very simple (and our favourite) way of exploring what is possible with our API.
  You can access GraphiQL by going to:

      https://developer-apis.awair.is/graphql?accessToken={{YourTokenHere}}

  You will be presented with a schema of the endpoints you can access depending on your account tier, so don't worry if all the endpoints in our API documentation do not appear.
  `;

const secondHalf = `## Test Requests
  For easier testing, we've created a set of tokens that simulate fake accounts with a given Tier. 
  Dummy data is returned if the requests is authorised, HTTP 401 if not.

      Hobbyist:
      eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiRFVNTVktSE9CQllJU1QifQ.hzjhIpGljqCZ8vCrOr89POy_ENDPYQXsnzGslP01krI

      Small Developer:
      eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiRFVNTVktU01BTExfREVWRUxPUEVSIn0.amOu5uy-0UeBDRLd6uhqsbkUEyx13-4QdBrV1S3z2W8

      Large Developer:
      eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiRFVNTVktTEFSR0VfREVWRUxPUEVSIn0.JmP9a0eGjgYRlmri5BjNj4h1hlAZ-7yFOjcIZjyzypA

      Enterprise:
      eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiRFVNTVktRU5URVJQUklTRSJ9.bOM9rcABF9HKFHtxzF9kx8h9fv3CfvUIzveLFDRGrXs
  
  ## What's Next?

  By using our [API documentation](http://docs.developer.getawair.com) to craft requests you should be well armed to create awesome projects with our APIs, but documentation can always be improved so don't hesitate to contact us with feedback or suggestions!

  We are working on putting together a set of example code snippets, which should illustrate some uses of the API more clearly.

`;

const GettingStartedDoc = () => {
  return (
    <div className="MainDocPane">
      <div className="DocPane">
        <div className="markdown-body" dangerouslySetInnerHTML={{ __html: md.render(firstHalf) }}></div>
        <p />
        <Button
          type="primary"
          href={graphQlHostName() + '/graphql?accessToken=' + JSON.parse(localStorage.getItem('apiAccessToken'))}
        >
          Take Me To GraphiQL!
        </Button>
        <p />
        <div className="markdown-body" dangerouslySetInnerHTML={{ __html: md.render(secondHalf) }}></div>
      </div>
    </div>
  );
};

export default GettingStartedDoc;
