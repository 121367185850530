import React from 'react';
import { Layout, Modal, Button, Avatar } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import Welcome from './welcome/Welcome';
import Login from './login/Login';
import RequestAccess from './request-access/RequestAccess';
import RequestAccessSuccess from './request-access-pending/RequestAccessPending';
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';

const { Content, Header } = Layout;

function userName() {
  if (!JSON.parse(localStorage.getItem('firstName')) || !JSON.parse(localStorage.getItem('lastName'))) {
    return 'An Awair User';
  } else {
    return `${JSON.parse(localStorage.getItem('firstName'))} ${JSON.parse(localStorage.getItem('lastName'))}`;
  }
}

const unauthenticatedClient = new ApolloClient({
  uri: unauthenticatedApiHostName(),
  cache: new InMemoryCache(),
});

function unauthenticatedApiHostName() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else if (env === 'stg') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else {
    return 'https://developer-console-apis.awair.is/unprotected-graphql';
  }
}

class Onboard extends React.Component {
  state = {
    profileModalVisible: false,
  };

  showProfileModal = () => {
    if (JSON.parse(localStorage.getItem('consoleAccessToken'))) {
      this.setState({
        profileModalVisible: true,
      });
    }
  };

  handleProfileModalOk = () => {
    this.setState({
      profileModalVisible: false,
    });
  };

  handleProfileModalCancel = () => {
    this.setState({
      profileModalVisible: false,
    });
  };

  onLogoutBtnClick = () => {
    localStorage.clear();
    this.setState({ profileModalVisible: false });
  };

  getHeaderItems = () => {
    if (JSON.parse(localStorage.getItem('consoleAccessToken'))) {
      return (
        <div className="Items">
          <a href="/console/getting-started-doc">Docs</a>
          <a>
            <Avatar
              className="ProfileButton"
              src="https://s3-us-west-2.amazonaws.com/docs.developer.getawair.com/user-icon.svg"
              onClick={this.showProfileModal}
            />
          </a>
        </div>
      );
    } else {
      return (
        <div className="Items">
          <a href="https://docs.developer.getawair.com/ ">Docs</a>
          <a href="/onboard/login">Sign in</a>
        </div>
      );
    }
  };

  render() {
    if (JSON.parse(localStorage.getItem('consoleAccessToken')) && JSON.parse(localStorage.getItem('isApproved'))) {
      return <Redirect to="/console" />;
    }
    return (
      <div className="Layout">
        <Header className="App-header">
          <a href="/onboard/welcome">
            <img
              className="Logo sharpen-img"
              alt="Awair Logo"
              src="https://s3-us-west-1.amazonaws.com/bitfinder/img/AWAIR+Developer+logo.png"
              height="30"
            />
          </a>
          {this.getHeaderItems()}
          <Modal
            title="Profile Information"
            visible={this.state.profileModalVisible}
            onOk={this.handhandleProfileModalOk}
            onCancel={this.handleProfileModalCancel}
            footer={null}
          >
            <div>
              <h4>Name</h4>
            </div>
            <p>{userName()}</p>
            <div>
              <h4>Email address</h4>
            </div>
            <p>{JSON.parse(localStorage.getItem('email'))}</p>
            <div>
              <Button type="danger" className="LogoutButton" onClick={this.onLogoutBtnClick}>
                Sign out
              </Button>
            </div>
          </Modal>
        </Header>
        <ApolloProvider client={unauthenticatedClient}>
          <Layout className="MainLayout">
            <Content className="MainContent">
              <Switch>
                <Route path="/onboard/welcome" render={() => <Welcome />} />
                <Route path="/onboard/login" render={() => <Login />} />
                <Route path="/onboard/request-access" render={() => <RequestAccess />} />
                <Route path="/onboard/request-access-pending" render={() => <RequestAccessSuccess />} />
                <Redirect from="/onboard" exact to="/onboard/welcome" />
              </Switch>
            </Content>
          </Layout>
        </ApolloProvider>
      </div>
    );
  }
}

export default Onboard;
