import React from 'react';
import './DataDocs.css';
import { Remarkable } from 'remarkable';

const md = new Remarkable();

const docsMarkdown = `
  # Data Guide

  This page is a guide to the units of measure and indices returned by the API, as well as which sensor readings are returned by different device types.

  #### Units of Measure
  |Sensor|Sensor Description|Units|Units Description|Range|
  |:------|:-----------------:|:-----:|:----------------:|-----:|
  |temp|Temperature|°C|degrees Celsius|-40 ~ 185|
  |humid|Humidity|%|relative humidity (RH%)|0 ~ 100|
  |co2|Carbon Dioxide (CO2)|ppm|parts per million|0 ~ 5,000|
  |voc|Total Volatile Organic Chemicals (TVOCs)|ppb|parts per billion|0 ~ 60,000|
  |dust|Particulate Mass (PM - Aggregate Dust)|μg/m³|micrograms per meter cubed|0 ~ 250|
  |pm25|Particulate Mass (PM2.5 - Fine Dust)|μg/m³|micrograms per meter cubed|0 ~ 1,000|
  |pm10|Particulate Mass (PM10 - Coarse Dust)|μg/m³|micrograms per meter cubed|0 ~ 1,000|

  #### Explanation of the Awair Index system
`;

const DataDocs = () => {
  return (
    <div className="DataDocPane">
      <div className="DocPane">
        <div
          className="markdown-body"
          dangerouslySetInnerHTML={{ __html: md.render(docsMarkdown) }}
        ></div>
        <div className="DataDocsIFrameDiv">
          <iframe
            title="GSheetsIFrame"
            width="100%"
            height="1518"
            frameBorder="0"
            scrolling="no"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSn-VKeh45lokFka32Iwa5nUl9DOah_Mc8EbJx1QWbYx9TfWQX5i2IST-25yMtyhbU16o3H2-CV_481/pubhtml?gid=0&single=true&widget=false&headers=false&range=a1:g64&chrome=false"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default DataDocs;
