import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'antd';
import 'antd/dist/antd.css';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';
import { LOGIN_ADMIN } from '../../global/gql';

const FormItem = Form.Item;

function forgotPasswordUrl() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-oauth-login.awair.is/forgot-password.html';
  } else if (env === 'stg') {
    return 'https://stg-oauth-login.awair.is/forgot-password.html';
  } else {
    return 'https://oauth-login.awair.is/forgot-password.html';
  }
}

function unauthenticatedApiHostName() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else if (env === 'stg') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else {
    return 'https://developer-console-apis.awair.is/unprotected-graphql';
  }
}

const unauthenticatedClient = new ApolloClient({
  uri: unauthenticatedApiHostName(),
  cache: new InMemoryCache(),
});

class Login extends React.Component {
  state = {
    loginLoading: false,
  };

  componentWillMount = () => {
    localStorage.clear();
  };

  handleSubmit = (e, login) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loginLoading: true });
        login({
          variables: { email: values.emailAddress, password: values.password },
        })
          .then((res) => {
            // console.log(res);
            localStorage.setItem('adminToken', res.data.login.consoleAccessToken);
            this.setState({ loginLoading: false });
          })
          .catch((res) => {
            this.setState({ loginLoading: false });
            alert('Login failed. Please check your Awair account email address and password.');
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (localStorage.getItem('adminToken')) {
      return <Redirect from="/admin/login" to="/admin/users" />;
    } else {
      return (
        <div className="OnboardMainPane">
          <ApolloProvider client={unauthenticatedClient}>
            <Card className="LoginCard">
              <div>
                <h3>Welcome to Admin Page of Developer Console</h3>
              </div>
              <p>Enter your Admin id and password below</p>
              <Mutation mutation={LOGIN_ADMIN}>
                {(login) => (
                  <Form onSubmit={(e) => this.handleSubmit(e, login)} className="LoginForm">
                    <FormItem>
                      {getFieldDecorator('emailAddress', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your email.',
                          },
                        ],
                      })(
                        <Input
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          placeholder="Email address"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input your password.',
                          },
                        ],
                      })(
                        <Input
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          type="password"
                          placeholder="Password"
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      <div>
                        <a className="login-form-forgot" href={forgotPasswordUrl()} target="_blank">
                          Forgot password
                        </a>
                      </div>
                      <Button
                        className="LoginButton"
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loginLoading}
                      >
                        Log in
                      </Button>
                      <div>
                        Or{' '}
                        <a href="https://getawair.com/pages/awair-apps" target="_blank" rel="noopener noreferrer">
                          download Awair app
                        </a>{' '}
                        and sign up!
                      </div>
                    </FormItem>
                  </Form>
                )}
              </Mutation>
            </Card>
          </ApolloProvider>
        </div>
      );
    }
  }
}

export default Login = withRouter(Form.create()(Login));
