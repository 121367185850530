import React from 'react';
import './OAuth2Doc.css';
import { Remarkable } from 'remarkable';

const md = new Remarkable();

const input = `
  # OAuth 2.0 Guide
  OAuth 2.0 is an authentication system which allows 3rd-party apps to access Awair data on behalf of Awair users. A user with an Awair account can send an _Authorization Grant_ to the third-party app by logging in through the Awair page.
  This _Authorization Grant_ can be exchanged for an API token. When used in a call to the Awair Developer API, this token allows the app to make requests as if it were the user.

  ![Awair OAuth 2.0 Flow](https://s3-us-west-2.amazonaws.com/docs.developer.getawair.com/OAuth2Flow.svg "Awair OAuth 2.0 Flow")

  ## Access Request

  1. Apps request access from users by directing the user to the Awair login page by calling:

          GET https://oauth-login.awair.is

     The list of query parameters is detailed in the API list at the bottom of the page, but note that **response_type** must always be set to \`"code"\`.

  2. The user will see a login page, where they input their awair credentials. If the user inputs the wrong credentials, they will _remain on the login page_.

  3. Upon entry of recognized credentials, the browser environment is redirected to the specified **redirect_uri**, with the following query parameters:

          GET {{redirect_uri}}?code={{code}}&state={{state}}

     The **code** parameter is the _Authorization Grant_ from the user, while **state** is application-specific.

  ## Token Exchange

  4. Token exchange is done in 2 parts. The first call should exchange the _Authorization Grant_ from step 3 to a _Refresh Token_ and a short-lived _API Token_, while all subsequent calls should use the _Refresh Token_ to renew the _API Token_.

     In either case, the same endpoint is called:

         POST https://oauth2.awair.is/v2/token

     The request must include the **client_id** and **client_secret** of the same Client that was submitted as a query parameter in step 1.

       *   ### Authorization Grant

           To exchange an _Authorization Grant_, the endpoint should be called with **grant_type** set to \`"authorization_code"\`, and **code** set to the value from step 3.

           The response body will include the \`access_token\` field, which is a short-lived _API Token_. It also includes the field \`refresh_token\`, which should be permanently stored for future use.

       *   ### Refresh Token

           Once an _API Token_ expires, any calls to the Awair Developer API will have a response with status code 422 with the body containing JSON:

                {
                  "message": "jwt expired"
                }

           When this occurs, a new token exchange must be performed. In this case, the **grant_type** parameter should be set to \`"refresh_token"\` and the **refresh_token** parameter should be set to the _Refresh Token_ that was obtained in the previous section.

           The response will be the same as in the previous section, but without the \`refresh_token\` field.

  5. The response will include the API token. This token can be used to make requests to the Awair Developer API in the name of the user.


  ## OAuth 2.0 Flow API List

  ### \`GET https://oauth-login.awair.is\`

  #### _Request Parameters_

  |Name          |Type         |Required Value  |Required     |Description                                                        |
  |:-------------|:-----------:|:--------------:|:-----------:|------------------------------------------------------------------:|
  |state         |\`string\`   |                |yes          |Can be used to hold application specific information, e.g. user ID.|
  |scope         |\`string\`   |                |yes          |                                                                   |
  |response_type |\`string\`   |"code"          |yes          |                                                                   |
  |client_id     |\`string\`   |                |yes          |Taken from _Create OAuth 2.0 Client_ page.                         |
  |redirect_uri  |\`string\`   |                |yes          |The browser will be redirected to this page once login is complete.|

  #### _Response_

  The indirect response is a \`GET\` request sent to the specified \`redirect_uri\` (see step 3 above).


  _________________________________________________________________
  ### \`POST https://oauth2.awair.is/v2/token\`

  #### _Request Parameters_


  The following parameters should be submitted as JSON in the request body.

  |Name          |Type         |Required Value                           |Required     |Description                                          |
  |:-------------|:-----------:|:---------------------------------------:|:-----------:|----------------------------------------------------:|
  |client_id     |\`string\`   |                                         |yes          |Taken from _Create OAuth 2.0 Client_ page.           |
  |client_secret |\`string\`   |                                         |yes          |Taken from _Create OAuth 2.0 Client_ page.           |
  |grant_type    |\`string\`   |"authorization_code" OR "refresh_token" |yes          |                                                     |
  |code          |\`string\`   |                                         |yes          |The code returned as a query parameters (See step 3).|


  #### _Response_

  The following will be returned as JSON in the response body.

  |Name          |Type         |Required Value        |Required     |Description                                          |
  |:-------------|:-----------:|:--------------------:|:-----------:|----------------------------------------------------:|
  |token_type    |\`string\`   | "Bearer"             |yes          |Marks \`access_token\` as a Bearer Token.            |
  |access_token  |\`string\`   |                      |yes          |Api Access Token.                                    |


`

const OAuth2 = () => {
  return (
    <div className="OauthDocPane">
      <div className="DocPane">
        <div className="markdown-body" dangerouslySetInnerHTML={{__html:md.render(input)}}></div>
      </div>
    </div>
  )
}

export default OAuth2;
