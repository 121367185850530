import React from 'react';
import { Layout, Menu, Button, Modal, Avatar } from 'antd';
import { Redirect, Route, NavLink, Switch } from 'react-router-dom';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider, InMemoryCache, ApolloClient, createHttpLink } from '@apollo/client';

import AccessToken from './AccessToken/AccessToken';
import OAuth2 from './oauth2/OAuth2';
import DataDocs from './DataDocs/DataDocs';
import OAuth2Doc from './oauth2-doc/OAuth2Doc';
import WhatsNew from './whats-new/WhatsNew';
import OrgDoc from './organization-doc/organization-doc';
import GettingStartedDoc from './getting-started-doc/GettingStartedDoc';
import Tiers from './tiers/Tiers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';

const { Sider, Content, Header } = Layout;

const httpLink = createHttpLink({
  uri: apiHostName(),
});

function userName() {
  if (!JSON.parse(localStorage.getItem('firstName')) || !JSON.parse(localStorage.getItem('lastName'))) {
    return 'An Awair User';
  } else {
    return `${JSON.parse(localStorage.getItem('firstName'))} ${JSON.parse(localStorage.getItem('lastName'))}`;
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem('consoleAccessToken'));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function apiHostName() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-console-apis.awair.is/graphql';
  } else if (env === 'stg') {
    return 'https://stg-developer-console-apis.awair.is/graphql';
  } else {
    return 'https://developer-console-apis.awair.is/graphql';
  }
}

class Console extends React.Component {
  state = {
    profileModalVisible: false,
    collapsed: Sider.collapsed,
    broken: Sider.broken,
  };

  toggleCollapsed = (collapse) => {
    this.setState({
      collapsed: collapse,
    });
  };
  toggleBroken = (broken) => {
    this.setState({
      broken: broken,
    });
  };
  onPageToggle = () => {
    if (this.state.broken) {
      this.toggleCollapsed(true);
    }
  };
  showProfileModal = () => {
    if (JSON.parse(localStorage.getItem('consoleAccessToken'))) {
      this.setState({
        profileModalVisible: true,
      });
    }
  };

  handleProfileModalOk = () => {
    this.setState({
      profileModalVisible: false,
    });
  };

  handleProfileModalCancel = () => {
    this.setState({
      profileModalVisible: false,
    });
  };

  onLogoutBtnClick = () => {
    localStorage.clear();
    this.setState({ profileModalVisible: false });
  };

  getHeaderItems = () => {
    if (JSON.parse(localStorage.getItem('consoleAccessToken')) && JSON.parse(localStorage.getItem('isApproved'))) {
      return (
        <div className="Items">
          <a>
            <Avatar
              className="ProfileButton"
              src="https://s3-us-west-2.amazonaws.com/docs.developer.getawair.com/user-icon.svg"
              onClick={this.showProfileModal}
            />
          </a>
        </div>
      );
    } else {
      return (
        <div className="Items">
          <a href="/onboard/login">Sign in</a>
        </div>
      );
    }
  };

  renderDocs = () => {
    return (
      <div className="Layout">
        <Header className="App-header">
          <div
            className="App-list"
            onClick={() => {
              this.toggleCollapsed(!this.state.collapsed);
            }}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
          <a href="/onboard/welcome">
            <img
              className="Logo-title sharpen-img"
              alt="Awair Logo"
              src="https://s3-us-west-1.amazonaws.com/bitfinder/img/AWAIR+Developer+logo.png"
              height="30"
            />
          </a>
          {this.getHeaderItems()}
        </Header>
        <Layout className="MainLayout">
          <Sider
            className="MainSider"
            width="220px"
            breakpoint="md"
            collapsedWidth="0"
            collapsed={this.state.collapsed}
            trigger={null}
            onBreakpoint={(broken) => {
              this.toggleCollapsed(broken);
              this.toggleBroken(broken);
            }}
            onCollapse={(collapsed, type) => {
              if (type === 'clickTrigger') {
                this.toggleCollapsed(collapsed);
              }
            }}
            theme="dark"
          >
            <Menu
              defaultSelectedKeys={[
                window.location.pathname === '/console' ? '/console/access-token' : window.location.pathname,
              ]}
              mode="inline"
              onClick={this.onPageToggle}
            >
              {/* <Menu.Item key="/console/getting-started-doc" className="getting-started-doc border-bottom">
                <NavLink to="/console/getting-started-doc">Getting Started</NavLink>
              </Menu.Item> */}
              {/* <Menu.Item key="/console/organization-doc" className="border-bottom">
                <NavLink to="/console/organization-doc">
                  Organization Guide
                </NavLink>
              </Menu.Item> */}
              <Menu.Item key="/console/oauth2-doc" className="oauth2-doc">
                <NavLink to="/console/oauth2-doc">OAuth 2.0 Guide</NavLink>
              </Menu.Item>
              <Menu.Item key="/console/api-doc" className="api-doc">
                <a href="https://docs.developer.getawair.com">API Documentation</a>
              </Menu.Item>
            </Menu>
          </Sider>
          <Content className="MainContent">
            <Switch>
              {/* <Route path="/console/getting-started-doc" render={() => <GettingStartedDoc />} /> */}
              {/* <Route
                path="/console/organization-doc"
                render={() => <OrgDoc />}
              /> */}
              {/* <Route path="/console/oauth2-doc" render={() => <OAuth2Doc />} /> */}
              {/* <Route path="/console/tiers" render={() => <Tiers />} /> */}
              <Redirect from="/console" to="/onboard/welcome" />
            </Switch>
          </Content>
        </Layout>
      </div>
    );
  };

  renderConsole = () => {
    return (
      <div className="Layout">
        <Header className="App-header">
          <div
            className="App-list"
            onClick={() => {
              this.toggleCollapsed(!this.state.collapsed);
            }}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
          <NavLink to="/onboard/welcome">
            <img
              className="Logo-title sharpen-img"
              alt="Awair Logo"
              src="https://s3-us-west-1.amazonaws.com/bitfinder/img/AWAIR+Developer+logo.png"
              height="30"
            />
          </NavLink>
          {this.getHeaderItems()}
          <Modal
            title="Profile Information"
            visible={this.state.profileModalVisible}
            onOk={this.handhandleProfileModalOk}
            onCancel={this.handleProfileModalCancel}
            footer={null}
          >
            <div>
              <h4>Name</h4>
            </div>
            <p>{userName()}</p>
            <div>
              <h4>Email address</h4>
            </div>
            <p>{JSON.parse(localStorage.getItem('email'))}</p>
            <div>
              <Button type="danger" className="LogoutButton" onClick={this.onLogoutBtnClick}>
                Sign out
              </Button>
            </div>
          </Modal>
        </Header>
        <ApolloProvider client={client}>
          <Layout className="MainLayout">
            <Sider
              className="MainSider"
              width="220px"
              breakpoint="md"
              collapsedWidth="0"
              collapsed={this.state.collapsed}
              trigger={null}
              onBreakpoint={(broken) => {
                this.toggleCollapsed(broken);
                this.toggleBroken(broken);
              }}
              onCollapse={(collapsed, type) => {
                if (type === 'clickTrigger') {
                  this.toggleCollapsed(collapsed);
                }
              }}
              theme="dark"
            >
              <Menu
                defaultSelectedKeys={[
                  window.location.pathname === '/console' ? '/console/access-token' : window.location.pathname,
                ]}
                mode="inline"
                onClick={this.onPageToggle}
              >
                {/* <Menu.Item
                  key="/console/whats-new"
                  className="console-whats-new"
                >
                  <NavLink to="/console/whats-new">What's New</NavLink>
                </Menu.Item> */}
                {/* <Menu.Item key="/console/getting-started-doc" className="console-getting-started-doc">
                  <NavLink to="/console/getting-started-doc">Getting Started</NavLink>
                </Menu.Item> */}
                <Menu.Item key="/console/access-token" className="access-token border-bottom">
                  <NavLink to="/console/access-token">Access Token</NavLink>
                </Menu.Item>
                {/* <Menu.Item key="/console/data-docs" className="data-docs">
                  <NavLink to="/console/data-docs">Data Guide</NavLink>
                </Menu.Item> */}
                {/* <Menu.Item
                  key="/console/organization-doc"
                  className="border-bottom"
                >
                  <NavLink to="/console/organization-doc">
                    Organization Guide
                  </NavLink>
                </Menu.Item> */}
                <Menu.Item key="/console/oauth2" className="oauth2">
                  <NavLink to="/console/oauth2">Create OAuth 2.0 App</NavLink>
                </Menu.Item>
                <Menu.Item key="/console/oauth2-doc" className="console-oauth2-doc">
                  <NavLink to="/console/oauth2-doc">OAuth 2.0 Guide</NavLink>
                </Menu.Item>
                <Menu.Item key="/console/api-doc" className="api-doc">
                  <a href="https://docs.developer.getawair.com">API Documentation</a>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content className="MainContent">
              <Switch>
                {/* <Route path="/console/whats-new" render={() => <WhatsNew />} /> */}
                <Route path="/console/access-token" render={() => <AccessToken />} />
                {/* <Route path="/console/data-docs" render={() => <DataDocs />} /> */}
                {/* <Route
                  path="/console/organization-doc"
                  render={() => <OrgDoc />}
                /> */}
                <Route path="/console/oauth2" render={() => <OAuth2 />} />
                <Route path="/console/oauth2-doc" render={() => <OAuth2Doc />} />
                {/* <Route path="/console/getting-started-doc" render={() => <GettingStartedDoc />} /> */}
                <Route path="/console/tiers" render={() => <Tiers />} />
                <Redirect from="/console" to="/console/access-token" />
              </Switch>
            </Content>
          </Layout>
        </ApolloProvider>
      </div>
    );
  };

  render() {
    if (JSON.parse(localStorage.getItem('consoleAccessToken')) && JSON.parse(localStorage.getItem('isApproved'))) {
      return this.renderConsole();
    } else {
      return this.renderDocs();
    }
  }
}

export default Console;
