import React from 'react';
import { Button, Card, Modal, Input, Spin } from 'antd';
import './OAuth2.css';
import '../Console.css';
import { Query, Mutation } from '@apollo/client/react/components';
import { CREATE_CLIENT, CLIENTS_QUERY, UPDATE_CLIENT, DELETE_CLIENT } from '../../global/gql';

const { TextArea } = Input;

export default class OAuth2 extends React.Component {
  state = {
    clientId: null,
    clientName: null,
    clientDescription: null,
    clientRedirectUri: null,
    createAppModalVisible: false,
    updateAppModalVisible: false,
    deleteAppModalVisible: false,
    goToTiersPage: false,
  };

  showCreateAppModal = () => {
    this.setState({
      createAppModalVisible: true,
    });
  };

  showUpdateAppModal = (e, id, name, description, redirectUri) => {
    this.setState({
      clientId: id,
      clientName: name,
      clientDescription: description,
      clientRedirectUri: redirectUri,
      updateAppModalVisible: true,
    });
  };

  handleCreateAppModalOk = (e, createClient, name, description, redirectUri) => {
    console.log(e);
    createClient({
      variables: {
        name: name,
        description: description,
        redirectUri: redirectUri,
      },
    });
    this.setState({
      createAppModalVisible: false,
    });
  };

  handleCreateAppModalCancel = (e) => {
    console.log(e);
    this.setState({
      createAppModalVisible: false,
    });
  };

  handleUpdateAppModalOk = (e, updateClient, clientId, name, description, redirectUri) => {
    console.log(e);
    console.log({
      clientId: clientId,
      name: name,
      description: description,
      redirectUri: redirectUri,
    });
    updateClient({
      variables: {
        clientId: clientId,
        name: name,
        description: description,
        redirectUri: redirectUri,
      },
    });
    this.setState({
      updateAppModalVisible: false,
    });
  };

  handleUpdateAppModalCancel = (e) => {
    console.log(e);
    this.setState({
      updateAppModalVisible: false,
    });
  };

  handleClientNameInputChange = (e) => {
    this.setState({ clientName: e.target.value });
  };

  handleClientDescriptionInputChange = (e) => {
    this.setState({ clientDescription: e.target.value });
  };

  handleClientRedirectUriInputChange = (e) => {
    this.setState({ clientRedirectUri: e.target.value });
  };

  showDeleteModal = (e, clientId) => {
    this.setState({
      clientId: clientId,
      deleteAppModalVisible: true,
    });
  };

  handleDeleteAppModalOk = (e, deleteClient, clientId) => {
    console.log(e);
    deleteClient({ variables: { clientId: clientId } });
    this.setState({
      deleteAppModalVisible: false,
    });
  };

  handleDeleteAppModalCancel = (e) => {
    console.log(e);
    this.setState({
      deleteAppModalVisible: false,
    });
  };

  handleGoToTiersPage = (e) => {
    this.setState({
      goToTiersPage: true,
    });
  };

  render = () => {
    return (
      <div className="MainPane">
        <div className="Header">
          <h1>Your Apps</h1>
          <Button type="primary" onClick={this.showCreateAppModal}>
            Create New App
          </Button>
          <Mutation mutation={CREATE_CLIENT} refetchQueries={[{ query: CLIENTS_QUERY }]}>
            {(createClient) => (
              <Modal
                title="Create new app"
                visible={this.state.createAppModalVisible}
                onOk={(e) =>
                  this.handleCreateAppModalOk(
                    e,
                    createClient,
                    this.state.clientName,
                    this.state.clientDescription,
                    this.state.clientRedirectUri
                  )
                }
                onCancel={this.handleCreateAppModalCancel}
              >
                <p>Enter details for your app below.</p>
                <div>
                  <h4>App Name</h4>
                </div>
                <Input
                  className="AppNameInput"
                  placeholder="Your app's name"
                  value={this.state.clientName}
                  onChange={this.handleClientNameInputChange}
                />
                <div>
                  <h4>App Description</h4>
                </div>
                <TextArea
                  className="AppNameInput"
                  rows={4}
                  value={this.state.clientDescription}
                  onChange={this.handleClientDescriptionInputChange}
                />
                <div>
                  <h4>Redirect URI</h4>
                </div>
                <Input
                  className="AppNameInput"
                  placeholder="URL to be redirected to for your app"
                  value={this.state.clientRedirectUri}
                  onChange={this.handleClientRedirectUriInputChange}
                />
              </Modal>
            )}
          </Mutation>
        </div>
        <Mutation mutation={DELETE_CLIENT} refetchQueries={[{ query: CLIENTS_QUERY }]}>
          {(deleteClient) => (
            <Modal
              title="Delete client"
              visible={this.state.deleteAppModalVisible}
              onOk={(e) => this.handleDeleteAppModalOk(e, deleteClient, this.state.clientId)}
              onCancel={this.handleDeleteAppModalCancel}
              footer={[
                <Button key="back" onClick={this.handleDeleteAppModalCancel}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="danger"
                  onClick={(e) => this.handleDeleteAppModalOk(e, deleteClient, this.state.clientId)}
                >
                  Delete
                </Button>,
              ]}
            >
              <p>Are you sure you want to delete this client?</p>
            </Modal>
          )}
        </Mutation>
        <div className="AppList">
          <Query query={CLIENTS_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <Spin></Spin>;
              if (error) return <p>Error :(</p>;
              if (data.clients.length === 0) {
                return <div className="EmptyList">You don't have any OAuth 2.0 client yet.</div>;
              } else {
                return data.clients.map(({ id, name, description, secret, redirectUri }) => {
                  return (
                    <Card title={name} key={id} className="AppCard" style={{ width: 600 }}>
                      <p className="AppDescription">{description}</p>
                      <div className="AppCredentials">
                        <p className="MiniTitle">CLIENT ID</p>
                        <div className="AppCredentialField">{id}</div>
                        <p className="MiniTitle">CLIENT SECRET</p>
                        <div className="AppCredentialField">{secret}</div>
                        <p className="MiniTitle">REDIRECT URI</p>
                        <div className="AppCredentialField">{redirectUri}</div>
                      </div>
                      <div className="AppButtonsRow">
                        <Button onClick={(e) => this.showUpdateAppModal(e, id, name, description, redirectUri)}>
                          Edit
                        </Button>
                        <Mutation mutation={UPDATE_CLIENT} refetchQueries={[{ query: CLIENTS_QUERY }]}>
                          {(updateClient) => (
                            <Modal
                              title="Update App"
                              visible={this.state.updateAppModalVisible}
                              onOk={(e) =>
                                this.handleUpdateAppModalOk(
                                  e,
                                  updateClient,
                                  this.state.clientId,
                                  this.state.clientName,
                                  this.state.clientDescription,
                                  this.state.clientRedirectUri
                                )
                              }
                              onCancel={this.handleUpdateAppModalCancel}
                            >
                              <div>
                                <h4>App Name</h4>
                              </div>
                              <Input
                                className="AppNameInput"
                                placeholder="Your app's name"
                                value={this.state.clientName}
                                onChange={this.handleClientNameInputChange}
                              />
                              <div>
                                <h4>App Description</h4>
                              </div>
                              <TextArea
                                rows={4}
                                className="AppNameInput"
                                value={this.state.clientDescription}
                                onChange={this.handleClientDescriptionInputChange}
                              />
                              <div>
                                <h4>Redirect URI</h4>
                              </div>
                              <Input
                                className="AppNameInput"
                                placeholder="URL to be redirected to for your app"
                                value={this.state.clientRedirectUri}
                                onChange={this.handleClientRedirectUriInputChange}
                              />
                            </Modal>
                          )}
                        </Mutation>
                        <Button className="DeleteAppButton" type="danger" onClick={(e) => this.showDeleteModal(e, id)}>
                          Delete
                        </Button>
                      </div>
                    </Card>
                  );
                });
              }
            }}
          </Query>
        </div>
      </div>
    );
  };
}
