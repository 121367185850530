import React from 'react';
import { withRouter } from 'react-router-dom';
import '../onboard.css';
import { Card } from 'antd';
import 'antd/dist/antd.css';
import './Login.css';
import { Form, Icon, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { InMemoryCache, ApolloClient } from '@apollo/client';
import { LOGIN } from '../../global/gql';

const FormItem = Form.Item;

const unauthenticatedClient = new ApolloClient({
  uri: unauthenticatedApiHostName(),
  cache: new InMemoryCache(),
});

function unauthenticatedApiHostName() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else if (env === 'stg') {
    return 'https://stg-developer-console-apis.awair.is/unprotected-graphql';
  } else {
    return 'https://developer-console-apis.awair.is/unprotected-graphql';
  }
}

function forgotPasswordUrl() {
  const hostName = window.location.hostname;
  const env = hostName.split('-')[0];
  if (env === 'localhost') {
    return 'https://stg-oauth-login.awair.is/forgot-password.html';
  } else if (env === 'stg') {
    return 'https://stg-oauth-login.awair.is/forgot-password.html';
  } else {
    return 'https://oauth-login.awair.is/forgot-password.html';
  }
}
class Login extends React.Component {
  state = {
    redirect: null,
    loginLoading: false,
  };

  componentWillMount = () => {
    localStorage.clear();
  };

  handleSubmit = (e, login) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loginLoading: true });
        this.handleLogin(values);
      }
    });
  };

  handleLogin = (values) => {
    unauthenticatedClient
      .mutate({
        mutation: LOGIN,
        variables: {
          email: values.emailAddress,
          password: values.password,
        },
      })
      .then((res) => {
        var login = res.data.login;
        localStorage.setItem('consoleAccessToken', JSON.stringify(login.consoleAccessToken));
        localStorage.setItem('apiAccessToken', JSON.stringify(login.apiAccessToken));
        localStorage.setItem('firstName', JSON.stringify(login.firstName));
        localStorage.setItem('lastName', JSON.stringify(login.lastName));
        localStorage.setItem('userId', JSON.stringify(res.data.login.id));
        localStorage.setItem('email', JSON.stringify(values.emailAddress));
        localStorage.setItem('hasRequestedAccess', JSON.stringify(login.hasRequestedAccess));
        localStorage.setItem('isApproved', JSON.stringify(login.isApproved));
        this.setState({ loginLoading: false });
      })
      .catch((error) => {
        console.error('handleLogin error: ', error);
        this.setState({ loginLoading: false });
        alert('Login failed. Please check your Awair account email address and password.');
      });
  };

  // minho+22@bitfinder.co
  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.redirect !== null) {
      if (this.state.redirect === 'console') {
        return <Redirect to="/console" />;
      } else if (this.state.redirect === 'request') {
        // return <Redirect to="/onboard/request-access" />;
        return <Redirect to="/console" />;
      }
    }
    const approved = JSON.parse(localStorage.getItem('isApproved'));
    const requested = localStorage.getItem('hasRequestedAccess');

    if (approved === true) {
      this.setState({ redirect: 'console' });
      return null;
    } else if (requested !== null && requested !== undefined) {
      this.setState({ redirect: 'request' });
      return null;
    } else {
      return (
        <div className="OnboardMainPane">
          <Card className="LoginCard">
            <div>
              <h3>Sign in to Awair Developer Console</h3>
            </div>
            <p>Enter your Awair account email and password below</p>
            <Form onSubmit={(e) => this.handleSubmit(e)} className="LoginForm">
              <FormItem>
                {getFieldDecorator('emailAddress', {
                  rules: [{ required: true, message: 'Please input your email.' }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email address"
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password.',
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                  />
                )}
              </FormItem>
              <FormItem className="LoginFormLastItem">
                <div>
                  <a className="login-form-forgot" href={forgotPasswordUrl()} target="_blank">
                    Forgot password
                  </a>
                </div>
                <Button className="LoginButton" type="primary" htmlType="submit" loading={this.state.loginLoading}>
                  Log in
                </Button>
                <a
                  className="login-form-forgot"
                  href="https://support.getawair.com/hc/en-us/articles/360058168454"
                  target="_blank"
                >
                  Third-party login support
                </a>
                <div>
                  Or{' '}
                  <a href="https://getawair.com/pages/awair-apps" target="_blank" rel="noopener noreferrer">
                    download Awair app
                  </a>{' '}
                  and sign up!
                </div>
              </FormItem>
            </Form>
          </Card>
        </div>
      );
    }
  }
}

export default Login = withRouter(Form.create()(Login));
