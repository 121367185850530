import React from 'react';
import '../onboard.css';
import './RequestAccess.css';
import { Card, Button } from 'antd';
import 'antd/dist/antd.css';
import { Mutation } from '@apollo/client/react/components';
import { Redirect } from 'react-router-dom';
import { REQUEST_ACCESS } from '../../global/gql';

export default class RequestAccess extends React.Component {
  state = {
    requestAccessLoading: false,
    goToRequestAccessPending: false,
  };

  handleRequestAccess = (e, requestAccess) => {
    const userId = JSON.parse(localStorage.getItem('userId'));
    requestAccess({ variables: { userId: userId } })
      .then(this.setState({ goToRequestAccessPending: true }))
      .catch((err) => {
        alert(`Something went wrong. Please try again in a few minutes after refreshing the page. error: ${err}`);
      });
  };

  render() {
    const requested = JSON.parse(localStorage.getItem('hasRequestedAccess'));
    if (requested || this.state.goToRequestAccessPending) {
      this.setState({ goToRequestAccessPending: false });
      return <Redirect to="/onboard/request-access-pending" />;
    } else {
      return (
        <div className="OnboardMainPane">
          <Card className="RequestAccessCard">
            <div>
              <h3>Join the Beta List</h3>
            </div>
            <p>
              We are accepting beta users for Developer Console. Please press the button below to request for access,
              and we'll get back to you within 48 hours.
            </p>
            <div className="RequestCompleteImgContainer">
              <img
                alt="Access Requested!"
                src="https://s3-us-west-1.amazonaws.com/bitfinder/img/request_complete.png"
              ></img>
            </div>
            <Mutation mutation={REQUEST_ACCESS}>
              {(requestAccess) => (
                <Button
                  className="RequestAccessButton"
                  type="primary"
                  htmlType="submit"
                  loading={this.state.requestAccessLoading}
                  onClick={(e) => this.handleRequestAccess(e, requestAccess)}
                >
                  Request for Access
                </Button>
              )}
            </Mutation>
          </Card>
        </div>
      );
    }
  }
}
