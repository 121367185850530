import React from 'react';
import { Button, Card, Form, Input, Modal, Select, Spin } from 'antd';
import { Redirect, withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import { PUT_TIER, USER_FROM_EMAIL, USER_FROM_ID, ACCEPT_USER } from '../../global/gql';

const Option = Select.Option;
const FormItem = Form.Item;

class Users extends React.Component {
  state = {
    userId: null,
    email: null,
    userFetched: false,
    changeTier: null,
    editUserTier: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (Number.isInteger(Number(values.userField))) {
          this.setState({ userId: values.userField });
        } else {
          this.setState({ email: values.userField });
        }
      }
    });
  };

  handleTierSelect = (value) => {
    this.setState({ changeTier: value });
  };

  editUserTier = (id, tier, putTierForUser) => {
    putTierForUser({ variables: { userId: id, tier: tier } }).then((res) => {
      console.log(`Updated User to ${tier}`);
      this.userSearch();
    });
  };

  editModal = () => {
    this.setState({ editUserTier: true });
  };

  editModalCancel = () => {
    this.setState({ editUserTier: false });
  };

  userSearch = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('tier');
    localStorage.removeItem('hasRequestedAccess');
    localStorage.removeItem('developerConsoleAcceptanceCode');
    this.setState({ editUserTier: false, changeTier: null });
  };

  approveUser = (id, code, acceptUser) => {
    acceptUser({ variables: { userId: id, code: code } }).then((res) => {
      console.log('approved User');
      this.userSearch();
    });
  };

  renderUser = () => {
    const id = JSON.parse(localStorage.getItem('userId'));
    const email = JSON.parse(localStorage.getItem('userEmail'));
    const firstName = JSON.parse(localStorage.getItem('firstName'));
    const lastName = JSON.parse(localStorage.getItem('lastName'));
    const tier = JSON.parse(localStorage.getItem('tier'));
    const approved = localStorage.getItem('isApproved');
    const requested = localStorage.getItem('hasRequestedAccess');
    const code = JSON.parse(localStorage.getItem('developerConsoleAcceptanceCode'));
    return (
      <Card title={'User ' + id} className="UserInfoCard">
        <p>email: {email}</p>
        <p>first name: {firstName}</p>
        <p>last name: {lastName}</p>
        <p>approved: {approved}</p>
        <p>requested: {requested}</p>
        <p>tier: {tier}</p>
        <Button onClick={this.editModal} disabled={!JSON.parse(approved)}>
          Edit Tier
        </Button>
        <Button onClick={this.userSearch}>Search Other User</Button>
        <Mutation
          mutation={PUT_TIER}
          refetchQueries={[
            { query: USER_FROM_EMAIL, variables: { email: email } },
            { query: USER_FROM_ID, variables: { userId: id } },
          ]}
          awaitRefetchQueries={true}
        >
          {(putTierForUser) => (
            <Modal
              title={'Edit User ' + id}
              visible={this.state.editUserTier}
              onOk={() => this.editUserTier(id, this.state.changeTier, putTierForUser)}
              onCancel={this.editModalCancel}
            >
              <Select
                placeholder={'Select new Tier for User ' + id}
                style={{ width: 300 }}
                onChange={this.handleTierSelect}
              >
                <Option value="hobbyist" disabled={tier === 'HOBBYIST'}>
                  hobbyist
                </Option>
                <Option value="small_developer" disabled={tier === 'SMALL_DEVELOPER'}>
                  small_developer
                </Option>
                <Option value="large_developer" disabled={tier === 'LARGE_DEVELOPER'}>
                  large_developer
                </Option>
                <Option value="enterprise" disabled={tier === 'ENTERPRISE'}>
                  enterprise
                </Option>
              </Select>
            </Modal>
          )}
        </Mutation>
        <Mutation
          mutation={ACCEPT_USER}
          refetchQueries={[
            { query: USER_FROM_EMAIL, variables: { email: email } },
            { query: USER_FROM_ID, variables: { userId: id } },
          ]}
          awaitRefetchQueries={true}
        >
          {(acceptUser) => (
            <Button
              onClick={() => this.approveUser(id, code, acceptUser)}
              disabled={JSON.parse(approved) || !JSON.parse(requested)}
            >
              Approve User
            </Button>
          )}
        </Mutation>
      </Card>
    );
  };

  render() {
    if (!localStorage.getItem('adminToken')) {
      return <Redirect from="/admin/users" to="/admin/login" />;
    }
    if (localStorage.getItem('tier')) {
      return this.renderUser();
    }
    if (this.state.userId) {
      return (
        <div>
          <Query query={USER_FROM_ID} variables={{ userId: this.state.userId }}>
            {({ loading, error, data }) => {
              if (loading) return <Spin />;
              if (error) return <p>Error :(</p>;
              console.log(data);
              localStorage.setItem('userId', JSON.stringify(data.userFromId.id));
              localStorage.setItem('userEmail', JSON.stringify(data.userFromId.email));
              localStorage.setItem('firstName', JSON.stringify(data.userFromId.firstName));
              localStorage.setItem('lastName', JSON.stringify(data.userFromId.lastName));
              localStorage.setItem('tier', JSON.stringify(data.userFromId.tier));
              localStorage.setItem('isApproved', JSON.stringify(data.userFromId.isApproved));
              localStorage.setItem('hasRequestedAccess', JSON.stringify(data.userFromId.hasRequestedAccess));
              localStorage.setItem(
                'developerConsoleAcceptanceCode',
                JSON.stringify(data.userFromId.developerConsoleAcceptanceCode)
              );
              this.setState({ userId: null });
              return null;
            }}
          </Query>
        </div>
      );
    } else if (this.state.email) {
      return (
        <Query query={USER_FROM_EMAIL} variables={{ email: this.state.email }}>
          {({ loading, error, data }) => {
            if (loading) return <Spin />;
            if (error) return <p>Error :(</p>;
            console.log(data);
            localStorage.setItem('userId', JSON.stringify(data.userFromEmail.id));
            localStorage.setItem('userEmail', JSON.stringify(data.userFromEmail.email));
            localStorage.setItem('firstName', JSON.stringify(data.userFromEmail.firstName));
            localStorage.setItem('lastName', JSON.stringify(data.userFromEmail.lastName));
            localStorage.setItem('tier', JSON.stringify(data.userFromEmail.tier));
            localStorage.setItem('isApproved', JSON.stringify(data.userFromEmail.isApproved));
            localStorage.setItem('hasRequestedAccess', JSON.stringify(data.userFromEmail.hasRequestedAccess));
            localStorage.setItem(
              'developerConsoleAcceptanceCode',
              JSON.stringify(data.userFromEmail.developerConsoleAcceptanceCode)
            );
            this.setState({ email: null });
            return null;
          }}
        </Query>
      );
    } else {
      const { getFieldDecorator } = this.props.form;
      return (
        <div>
          <Card className="UserInputCard" title="Search User">
            <Form onSubmit={(e) => this.handleSubmit(e)} className="LoginForm" style={{ width: 400 }}>
              <FormItem>
                {getFieldDecorator('userField', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input User ID or Email.',
                    },
                  ],
                })(<Input placeholder="User ID or Email address" />)}
              </FormItem>
              <Button className="SearchButton" type="primary" htmlType="submit">
                Search
              </Button>
            </Form>
          </Card>
        </div>
      );
    }
  }
}

export default Users = withRouter(Form.create()(Users));
